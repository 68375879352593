import { Analytics, getAnalytics, setConsent } from '@angular/fire/analytics';
import { inject } from '@angular/core';
import { CapacitorGetPlatformService } from '../capacitor-get-platform.service';

/**
 * Disable tracking on non-web platforms in order to be compliant with iOS and
 * Android App Store rules.
 */
export const getAnalyticsForWeb = (): Analytics => {
  const cgp = inject(CapacitorGetPlatformService);
  if (cgp.getPlatform() !== 'web') {
    // Turn off tracking on non-web platforms
    setConsent({
      ad_storage: 'denied',
      analytics_storage: 'denied',
      functionality_storage: 'denied',
      security_storage: 'denied',
      personalization_storage: 'denied',
    });
  }
  return getAnalytics();
};
