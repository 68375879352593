import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Analytics, logEvent } from '@angular/fire/analytics';

/** From firebase.analytics.EventNameString */
type EventNameString =
  | 'add_payment_info'
  | 'add_shipping_info'
  | 'add_to_cart'
  | 'add_to_wishlist'
  | 'begin_checkout'
  | 'checkout_progress'
  | 'exception'
  | 'generate_lead'
  | 'login'
  | 'page_view'
  | 'purchase'
  | 'refund'
  | 'remove_from_cart'
  | 'screen_view'
  | 'search'
  | 'select_content'
  | 'select_item'
  | 'select_promotion'
  | 'set_checkout_option'
  | 'share'
  | 'sign_up'
  | 'timing_complete'
  | 'view_cart'
  | 'view_item'
  | 'view_item_list'
  | 'view_promotion'
  | 'view_search_results';

export type PadspinEvent =
  | EventNameString
  | 'click_header_logo'
  | 'click_post_pad_form'
  | 'click_post_pad'
  | 'click_browse_pads'
  | 'click_social_media_link'
  | 'conversion'
  | 'watch_marketing_video'
  | 'open_pad_booking_dialog'
  | 'close_pad_booking_dialog_form_incomplete'
  | 'close_pad_booking_dialog_registration_incomplete'
  | 'close_pad_booking_dialog_booking_complete'
  | 'pad_booking_form_valid'
  | 'submit_post_a_pad_form'
  | 'click_login'
  | 'click_sign_up'
  | 'book_showing_signup_completed'
  | 'submit_matching_form'
  | 'click_start_the_bidding'
  | 'view_blog_list';

@Injectable({
  providedIn: 'root',
})
export class GoogleAnalyticsService {
  #events$ = new Subject<PadspinEvent | string>();
  event$: Observable<PadspinEvent | string> = this.#events$.asObservable();

  constructor(
    private readonly analytics: Analytics,
    private readonly router: Router
  ) {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event) => {
        const navigationEvent = event as NavigationEnd;
        this.log('page_view', {
          page_path: navigationEvent.urlAfterRedirects,
        });
      });
  }

  async log(
    event: PadspinEvent | EventNameString,
    eventParams?: Parameters<typeof logEvent>[2]
  ): Promise<void> {
    this.#events$.next(event);
    logEvent(this.analytics, event as string, eventParams);
  }
}
