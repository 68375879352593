import {
  inject,
  Injectable,
  Injector,
  createNgModule,
  NgModuleRef,
  ApplicationRef,
} from '@angular/core';
import {
  Dialog,
  DialogConfig,
  DialogModule,
  DialogRef,
} from '@angular/cdk/dialog';
import type { ComponentType } from '@angular/cdk/overlay';
import {
  defer,
  firstValueFrom,
  Observable,
  shareReplay,
  switchMap,
} from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { BasePortalOutlet } from '@angular/cdk/portal';

/**
 * Map between each modal component's name, and it's path relative to the modals
 * directory, minus ".component.ts" so that WebPack can create chunks for each
 * file in the modals directory that ends in ".component.ts".
 *
 * We can add every modal in the app to this enum, and then use it to load the
 * modal lazily _without_ having to include the transitive dependencies of all
 * modals in the root module.
 *
 * Ensure these components are not imported into this file, or they will be
 * transitively included in the bundle.
 */
export const enum LazilyLoadedModals {
  AfterBookViewingModalComponent = 'AfterBookViewingModalComponent',
  AfterSendPasswordResetRequestModalComponent = 'AfterSendPasswordResetRequestModalComponent',
  BookingAdminDialogComponent = 'BookingAdminDialogComponent',
  CannedMessagesEditorModalComponent = 'CannedMessagesEditorModalComponent',
  ConversationListCreateModalComponent = 'ConversationListCreateModalComponent',
  CreateSocialMediaModalComponent = 'CreateSocialMediaModalComponent',
  CredentialEditorModalComponent = 'CredentialEditorModalComponent',
  DashboardAdminLeadsQueueStatusModalComponent = 'DashboardAdminLeadsQueueStatusModalComponent',
  DownloadImagesModalComponent = 'DownloadImagesModalComponent',
  ExperianTutorialModalComponent = 'ExperianTutorialModalComponent',
  LeadEditModalComponent = 'LeadEditModalComponent',
  LoginOrRegisterModalComponent = 'LoginOrRegisterModalComponent',
  ListingEditModalComponent = 'ListingEditModalComponent',
  NewPasswordModalComponent = 'NewPasswordModalComponent',
  PadBookShowingModalComponent = 'PadBookShowingModalComponent',
  PadExpandedCarouselDialogComponent = 'PadExpandedCarouselDialogComponent',
  SendApplicationModalComponent = 'SendApplicationModalComponent',
  SendPasswordResetRequestModalComponent = 'SendPasswordResetRequestModalComponent',
  UserDetailsModalComponent = 'UserDetailsModalComponent',
  OnboardingModalComponent = 'OnboardingModalComponent',
  VersionModalComponent = 'VersionModalComponent',
}

/**
 * Open modal dialogs _without_ including them and their transitive dependencies
 * in the bundle.
 *
 * This service requires the `tsconfig.lib.json`.includes to include the
 * directory with the modals. All modals loaded by this service must be in the
 * `modals` directory.
 *
 * ```json
 * {
 *   "include": ["**\/*.ts", "**\/*modal.component.ts"]
 * }
 * ```
 *
 * @usageNotes
 * Open a modal dialog:
 * ```typescript
 * const mlls = inject(ModalLazyLoaderService);
 * const data = { uid: '123' };
 * const dialogRef = await mlls.openModal<void>(
 *   LazilyLoadedModalsEnum.UserDetailsModalComponent,
 *   data
 * );
 * const output = await firstValueFrom(dialogRef.afterClosed());
 * ```
 *
 * Adding a new modal:
 * ```typescript
 * // Create the modal component, it myst be standalone.
 * @Component({
 *   standalone: true,
 *   template: `<button (click)="close()">Close</button>`,
 * }) export class MyModal {
 *   close(): void { inject(DialogRef<MyModal>).close({ foo: 'bar' }); }
 * };
 *
 * // Add the name and path to the `LazilyLoadedModals` enum. Exclude '.ts'.
 * export const enum LazilyLoadedModals {
 *   ...
 *   MyModalComponent = './my-modal.component',
 * }
 *
 * // Use the modal in your code.
 * import {
 *   ModalLazyLoaderService,
 *   LazilyLoadedModals
 * } from './modal-lazy-loader.service';
 * @Injectable() export class SomeService {
 *   async openModalWithoutImportingIt(): Promise<void> {
 *     const mlls = inject(ModalLazyLoaderService);
 *     const dr = await mlls.openModal<{ foo: string }>(LazilyLoadedModals.MyModal, data);
 *     const res: { foo: string } = await firstValueFrom(dr.afterClosed());
 *   }
 * }
 * ``
 */
@Injectable({ providedIn: 'root' })
export class ModalLazyLoaderService {
  private readonly injector = inject(Injector);
  private readonly ar = inject(ApplicationRef);

  private maybeCDKDialogModule?: DialogModule;
  private maybeCDKNgModuleRef?: NgModuleRef<DialogModule>;
  private maybeCDKDialog?: Dialog;

  private async getCDKDialog(): Promise<Dialog> {
    if (this.maybeCDKDialog) {
      return this.maybeCDKDialog;
    }
    const { service, module, ngModuleRef } = await this.loadCDKDialog();
    this.maybeCDKDialog = service;
    this.maybeCDKDialogModule = module;
    this.maybeCDKNgModuleRef = ngModuleRef;

    return service;
  }

  private async loadCDKDialog(): Promise<{
    module: DialogModule;
    ngModuleRef: NgModuleRef<DialogModule>;
    service: Dialog;
  }> {
    const { DialogModule } = await import('@angular/cdk/dialog');
    const ngModuleRef = createNgModule(DialogModule, this.injector);
    const service = ngModuleRef.injector.get(Dialog);
    const module = ngModuleRef.instance;
    return { service, module, ngModuleRef };
  }

  /**
   * Open a modal dialog without including it, and it's transitive dependencies
   * in the application bundle.
   *
   * Generic arguments are `<ReturnType, DataInputType, PortalOutletType>`.
   * @param modal Which modal to open from the list of supported modals.
   * @param config The `DialogConfig` to pass to the modal.
   */
  async openCDKModal<Ret, Dat, C extends BasePortalOutlet = BasePortalOutlet>(
    modal: LazilyLoadedModals,
    config: DialogConfig<Dat, DialogRef<Ret, C>> = {}
  ): Promise<DialogRef<Ret, C>> {
    return firstValueFrom(this.openCDKModal$(modal, config));
  }

  /**
   * Open a modal dialog without including it, and it's transitive dependencies
   * in the application bundle.
   *
   * Generic arguments are `<ReturnType, DataInputType, PortalOutletType>`.
   * @param modal Which modal to open from the list of supported modals.
   * @param config The `DialogConfig` to pass to the modal.
   */
  openCDKModal$<Ret, Dat, C extends BasePortalOutlet = BasePortalOutlet>(
    modal: LazilyLoadedModals,
    config: DialogConfig<Dat, DialogRef<Ret, C>> = {}
  ): Observable<DialogRef<Ret, C>> {
    return defer(() => this.loadCDKModalComponent$<C>(modal)).pipe(
      switchMap((component) =>
        this.openCDKDialog<Dat, Ret, C>(component, config)
      ),
      tap(() => {
        // Child components may be rendered un-styled if the dialog is opened
        // right after the page loads.
        this.ar.tick();
      }),
      shareReplay({ bufferSize: 1, refCount: false })
    );
  }

  private loadCDKModalComponent$<C>(
    modal: LazilyLoadedModals
  ): Observable<ComponentType<C>> {
    return defer(async () => {
      // Use a switch statement so WebPack can create a chunk for each modal.
      // Trying to be clever and using a map will not work because WebPack
      // will not be able to statically analyze the map.
      switch (modal) {
        case LazilyLoadedModals.AfterBookViewingModalComponent:
          return await import(
            './modals/after-book-viewing-modal/after-book-viewing-modal.component'
          );
        case LazilyLoadedModals.AfterSendPasswordResetRequestModalComponent:
          return await import(
            './modals/after-send-password-reset-request-modal/after-send-password-reset-request-modal.component'
          );
        case LazilyLoadedModals.BookingAdminDialogComponent:
          return await import(
            './modals/booking-admin-dialog/booking-admin-dialog.component'
          );
        case LazilyLoadedModals.CannedMessagesEditorModalComponent:
          return await import(
            './modals/canned-messages-editor-modal/dashboard-admin-canned-messages-editor-modal.component'
          );
        case LazilyLoadedModals.ConversationListCreateModalComponent:
          return await import(
            './modals/conversation-list-create-modal/conversation-list-create-modal.component'
          );
        case LazilyLoadedModals.CreateSocialMediaModalComponent:
          return await import(
            './modals/create-social-media-modal/create-social-media-modal.component'
          );
        case LazilyLoadedModals.CredentialEditorModalComponent:
          return await import(
            './modals/credential-editor-modal/credential-editor-modal.component'
          );
        case LazilyLoadedModals.DashboardAdminLeadsQueueStatusModalComponent:
          return await import(
            './modals/dashboard-admin-leads-queue-status/dashboard-admin-leads-queue-status-modal.component'
          );
        case LazilyLoadedModals.DownloadImagesModalComponent:
          return await import(
            './modals/download-images-modal/download-images-modal.component'
          );
        case LazilyLoadedModals.ExperianTutorialModalComponent:
          return await import(
            './modals/experian-tutorial-modal/experian-tutorial-modal.component'
          );
        case LazilyLoadedModals.LeadEditModalComponent:
          return await import(
            './modals/lead-edit-modal/lead-edit-modal.component'
          );
        case LazilyLoadedModals.ListingEditModalComponent:
          return await import(
            './modals/listing-edit-modal/listing-edit-modal.component'
          );
        case LazilyLoadedModals.LoginOrRegisterModalComponent:
          return await import(
            './modals/login-or-register-modal/login-or-register-modal.component'
          );
        case LazilyLoadedModals.NewPasswordModalComponent:
          return await import(
            './modals/new-password-modal/new-password-modal.component'
          );
        case LazilyLoadedModals.PadBookShowingModalComponent:
          return await import(
            './modals/pad-book-showing-modal/pad-book-showing-modal.component'
          );
        case LazilyLoadedModals.PadExpandedCarouselDialogComponent:
          return await import(
            './modals/pad-expanded-carousel-dialog/pad-expanded-carousel-dialog.component'
          );
        case LazilyLoadedModals.SendApplicationModalComponent:
          return await import(
            './modals/send-application-modal/send-application-modal.component'
          );
        case LazilyLoadedModals.SendPasswordResetRequestModalComponent:
          return await import(
            './modals/send-password-reset-request-modal/send-password-reset-request-modal.component'
          );
        case LazilyLoadedModals.UserDetailsModalComponent:
          return await import(
            './modals/user-details-modal/user-details-modal.component'
          );
        case LazilyLoadedModals.OnboardingModalComponent:
          return await import(
            './modals/onboarding-modal/onboarding-modal.component'
          );
        case LazilyLoadedModals.VersionModalComponent:
          return await import('./modals/version-modal/version-modal.component');
      }
      // If we add a modal, the build should fail because not all code paths
      // return a value.
    }).pipe(
      map((chunk) => Object.values(chunk)[0] as ComponentType<C>),
      shareReplay({ bufferSize: 1, refCount: false })
    );
  }

  private async openCDKDialog<D, R, C extends BasePortalOutlet>(
    component: ComponentType<C>,
    config: DialogConfig<D, DialogRef<R, C>>
  ): Promise<DialogRef<R, C>> {
    const cdkDialog = await this.getCDKDialog();
    return cdkDialog.open(component, config);
  }
}
