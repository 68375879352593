import {
  EnvironmentProviders,
  makeEnvironmentProviders,
  NgModule,
} from '@angular/core';
import {
  HttpClientJsonpModule,
  HttpClientModule,
  provideHttpClient,
  withJsonpSupport,
} from '@angular/common/http';
import { MapLoadingService } from './map-loading.service';

export function provideLoadingService(): EnvironmentProviders {
  return makeEnvironmentProviders([
    MapLoadingService,
    provideHttpClient(withJsonpSupport()),
  ]);
}

@NgModule({
  imports: [HttpClientModule, HttpClientJsonpModule],
  exports: [HttpClientModule, HttpClientJsonpModule],
  providers: [MapLoadingService, provideHttpClient(withJsonpSupport())],
})
export class MapLoadingServiceModule {}
