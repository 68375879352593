import {
  ModuleWithProviders,
  EnvironmentProviders,
  importProvidersFrom,
  Provider,
} from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { FacebookPixelModule } from '../facebook-pixel/facebook-pixel.module';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { webEnvironment } from '../../environments/environment';
import { getFunctions, provideFunctions } from '@angular/fire/functions';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { getStorage, provideStorage } from '@angular/fire/storage';
import {
  getRemoteConfig,
  provideRemoteConfig,
} from '@angular/fire/remote-config';
import { provideAnalytics } from '@angular/fire/analytics';
import { getAnalyticsForWeb } from './get-analytics-for-web';
import { provideHttpClient, withJsonpSupport } from '@angular/common/http';
import { provideLoadingService } from '../map-loading-service/map-loading-service.module';

const globalModulesWithProviders: ModuleWithProviders<unknown>[] = [
  FacebookPixelModule.forRoot({ enabled: true, pixelId: '311269089285285' }),
  provideFirebaseApp(() => initializeApp(webEnvironment.firebase)),
  provideFunctions(() => getFunctions()),
  provideFirestore(() => getFirestore()),
  provideAuth(() => getAuth()),
  provideStorage(() => getStorage()),
  provideRemoteConfig(() => getRemoteConfig()),
  provideAnalytics(() => getAnalyticsForWeb()),
];

/**
 * Global providers to be included in the application configuration:
 *@example
 * ```typescript
 * import { applicationConfigProviders } from '@padspin/web';
 * export const appConfig: ApplicationConfig = {
 *   providers: [
 *     provideClientHydration(),
 *     ...applicationConfigProviders,
 *   ],
 * };
 * ```
 */
export const applicationConfigProviders: (Provider | EnvironmentProviders)[] = [
  importProvidersFrom(globalModulesWithProviders),
  provideAnimations(),
  provideHttpClient(withJsonpSupport()),
  provideLoadingService(),
];
